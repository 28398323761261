import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Loader } from "../src/shared/components";
import { useAuth } from "../state/AuthContexts";

export default function Home() {
  const router = useRouter();
  const { user, company } = useAuth();

  useEffect(() => {
    window && window.Intercom && window.Intercom("update");

    // If they are navigating to the root page, lets redirect.
    if (router.asPath === "/") {
      // If they are a user, lets..
      if (user) {
        // See if theyre onboarded:
        if (
          company?.OnboardingStatus?.onboarded &&
          company?.expected_activity
        ) {
          // If they're a buyer:
          if (company.expected_activity === "buyer") {
            router.push("/bills");
          }
          router.push("/invoices");
          // Otherwise lets take them to setup!
        } else {
          router.push("/setup");
        }
      }
      // Not navigating to the root page? They must be just reloading the page
    } else {
      // Lets check if their reload is to check if they're verification step happened
      if (user && user.verified && router.asPath === "/signup/verify") {
        router.push("/setup");
      }
      // else, just reload the page
      router.push(router.asPath);
    }
  }, [user, router, company?.OnboardingStatus?.onboarded, company]);

  return (
    <>
      <Head>
        <title>Quickly</title>
      </Head>
      <div className="h-screen" data-testid="home">
        <Loader size="10" label="Redirecting..." />
      </div>
    </>
  );
}
